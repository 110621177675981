/**
 * @file base/_typography-helpers.scss
 *
 * \brief Re-usable text styles
 *
 */

// 2019 Redesign
// New typography helper classes

// Text styles
// The following are selectable choices in mantle_custom_text_format
// We also have to account for base theme and overriding default styles
// Options are set via config in settings.php
.title--xxl,
h1.title--xxl,
h2.title--xxl,
h3.title--xxl,
h4.title--xxl,
h5.title--xxl,
h6.title--xxl,
.title--xxl p,
p.title--xxl {
  @include text-title--xxl;
}

.title--xl,
h1.title--xl,
h2.title--xl,
h3.title--xl,
h4.title--xl,
h5.title--xl,
h6.title--xl,
.title--xl p,
p.title--xl {
  @include text-title--xl;
}

.title--large,
h1.title--large,
h2.title--large,
h3.title--large,
h4.title--large,
h5.title--large,
h6.title--large,
.title--large p,
p.title--large {
  @include text-title--large;
}

.title--med,
h1.title--med,
h2.title--med,
h3.title--med,
h4.title--med,
h5.title--med,
h6.title--med,
.title--med p,
p.title--med {
  @include text-title--med;
}

.title--small,
h1.title--small,
h2.title--small,
h3.title--small,
h4.title--small,
h5.title--small,
h6.title--small,
.title--small p,
p.title--small {
  @include text-title--small;
}

.body-text--large,
h1.body-text--large,
h2.body-text--large,
h3.body-text--large,
h4.body-text--large,
h5.body-text--large,
h6.tbody-text--large,
.tbody-text--large p,
p.tbody-text--large {
  @include text-body-text--large;
}

.body-bold-text--large,
h1.body-bold-text--large,
h2.body-bold-text--large,
h3.body-bold-text--large,
h4.body-bold-text--large,
h5.body-bold-text--large,
h6.tbody-text--large,
.tbody-text--large p,
p.tbody-text--large {
  @include text-body-text--large($font-family: $helvetica-bold-font-family);
}

.body-text,
h1.body-text,
h2.body-text,
h3.body-text,
h4.body-text,
h5.body-text,
h6.tbody-text,
h6.tbody-text,
.tbody-text p,
.body-text p,
p.tbody-text {
  @include text-body-text;
}

.body-bold-text,
h1.body-bold-text,
h2.body-bold-text,
h3.body-bold-text,
h4.body-bold-text,
h5.body-bold-text,
h6.body-bold-text,
h6.body-bold-text,
.tbody-bold-text p,
.body-bold-text p,
p.tbody-bold-text {
  @include text-body-text($font-family: $helvetica-bold-font-family);
}

.body-text--small,
h1.body-text--small,
h2.body-text--small,
h3.body-text--small,
h4.body-text--small,
h5.body-text--small,
h6.tbody-text--small,
.tbody-text--small p,
p.tbody-text--small {
  @include text-body-text--small;
}

.special-text--callout,
h1.special-text--callout,
h2.special-text--callout,
h3.special-text--callout,
h4.special-text--callout,
h5.special-text--callout,
h6.special-text--callout,
.special-text--callout p,
p.special-text--callout {
  @include text-special--callout;
}

.special-bold-text--callout,
h1.special-bold-text--callout,
h2.special-bold-text--callout,
h3.special-bold-text--callout,
h4.special-bold-text--callout,
h5.special-bold-text--callout,
h6.special-bold-text--callout,
.special-bold-text--callout p,
p.special-bold-text--callout {
  @include text-special--callout($font-family: $nitti-bold-font-family);
}

.special-text--large,
h1.special-text--large,
h2.special-text--large,
h3.special-text--large,
h4.special-text--large,
h5.special-text--large,
h6.special-text--large,
.special-text--large p,
p.special-text--large {
  @include text-special--large;
}

.special-bold-text--large,
h1.special-bold-text--large,
h2.special-bold-text--large,
h3.special-bold-text--large,
h4.special-bold-text--large,
h5.special-bold-text--large,
h6.special-bold-text--large,
.special-bold-text--large p,
p.special-bold-text--large {
  @include text-special--large($font-family: $nitti-bold-font-family);
}

.special-text--body,
h1.special-text--body,
h2.special-text--body,
h3.special-text--body,
h4.special-text--body,
h5.special-text--body,
h6.special-text--body,
.special-text--body p,
p.special-text--body {
  @include text-special-body;
}

.special-bold-text--body,
h1.special-bold-text--body,
h2.special-bold-text--body,
h3.special-bold-text--body,
h4.special-bold-text--body,
h5.special-bold-text--body,
h6.special-bold-text--body,
.special-bold-text--body p,
p.special-bold-text--body {
  @include text-special-body($font-family: $nitti-bold-font-family);
}

.special-text--body-small,
h1.special-text--body-small,
h2.special-text--body-small,
h3.special-text--body-small,
h4.special-text--body-small,
h5.special-text--body-small,
h6.special-text--body-small,
.special-text--body-small p,
p.special-text--body-small {
  @include text-special-body-small;
}

.body-text--fine,
h1.body-text--fine,
h2.body-text--fine,
h3.body-text--fine,
h4.body-text--fine,
h5.body-text--fine,
h6.tbody-text--fine,
.tbody-text--fine p,
p.tbody-text--fine {
  @include text-body-text--fine;
}

.body-bold-text--fine,
h1.body-bold-text--fine,
h2.body-bold-text--fine,
h3.body-bold-text--fine,
h4.body-bold-text--fine,
h5.body-bold-text--fine,
h6.body-bold-text--fine,
.body-bold-text--fine p,
p.body-bold-text--fine {
  @include text-body-text--fine($font-family: $helvetica-bold-font-family);
}

.body-text--tags,
h1.body-text--tags,
h2.body-text--tags,
h3.body-text--tags,
h4.body-text--tags,
h5.body-text--tags,
h6.tbody-text--tags,
.tbody-text--tags p,
p.tbody-text--tags {
  @include text-body-text--tags;
}

// Font families
// Helvetica Neue
// HelveticaNeueLTStd55Roman
.hn-lt-std-55-roman {
  font-family: $base-font-family-roman;
}
// HelveticaNeueLTStd75Bold
.hn-lt-std-75-bold {
  font-family: $base-bolder-font-family;
}

// Older fonts - not used in new typography helpers
// HelveticaNeueLTStd45Light
.hn-lt-std-45-light {
  font-family: $base-font-family;
}
// HelveticaNeueLTStd65Medium
.hn-lt-std-65-medium {
  font-family: $base-bold-font-family;
}
// HelveticaNeueLTStd95Black
.hn-lt-std-95-black {
  font-family: $base-black-font-family;
}
// HelveticaNeueLTStd56Italic
.hn-lt-std-56-italic {
  font-family: $base-italic-font-family;
}
// HelveticaNeueLTStd-UltLt
.hn-lt-std-ultralight {
  font-family: $base-light-font-family;
}
// HelveticaNeueLTStd35Thin
.hn-lt-std-thin {
  font-family: $base-thin-font-family;
}

// CliniqueHelveticaNeueRegular
.clhn-regular {
  font-family: $helvetica-regular-font-family;
}

// CliniqueHelveticaNeueLight
.clhn-light {
  font-family: $helvetica-light-font-family;
}

// CliniqueHelveticaNeueBold
.clhn-bold {
  font-family: $helvetica-bold-font-family;
}

// CliniqueHelveticaNeueItalic
.clhn-italic {
  font-family: $helvetica-italic-font-family;
}

// Wink specific fonts
// NeuzeitOffice-Regular
.font--neuzeit-office {
  font-family: $wink-font-family-1;
}
// NeuzeitOffice-Bold
.font--neuzeit-office-bold {
  font-family: $wink-font-family-2;
}
// Georgia
.font--georgia {
  font-family: $wink-font-family-3;
}

// NittiRegular
.nitti-regular {
  font-family: $nitti-regular-font-family;
}

// NittiMedium
.nitti-medium {
  font-family: $nitti-medium-font-family;
}

// NittiBold
.nitti-bold {
  font-family: $nitti-bold-font-family;
}

// Text colors
// The following are selectable choices in mantle_custom_text_format
// Options are set via config in settings.php
.text-color,
h1.text-color,
h2.text-color,
h3.text-color,
h4.text-color,
h5.text-color,
h6.text-color,
p.text-color,
.text-color p {
  color: $cr19-text;
}

.text-color--black,
h1.text-color--black,
h2.text-color--black,
h3.text-color--black,
h4.text-color--black,
h5.text-color--black,
h6.text-color--black,
p.text-color--black,
.text-color--black p {
  color: $cr19-text-black;
}

.text-color--white,
h1.text-color--white,
h2.text-color--white,
h3.text-color--white,
h4.text-color--white,
h5.text-color--white,
h6.text-color--white,
p.text-color--white,
.text-color--white p {
  color: $cr19-text-white;
}

.text-color--grey,
h1.text-color--grey,
h2.text-color--grey,
h3.text-color--grey,
h4.text-color--grey,
h5.text-color--grey,
h6.text-color--grey,
p.text-color--grey,
.text-color--grey p {
  color: $cr19-text-grey;
}

.text-color--error,
h1.text-color--error,
h2.text-color--error,
h3.text-color--error,
h4.text-color--error,
h5.text-color--error,
h6.text-color--error,
p.text-color--error,
.text-color--error p {
  color: $cr19-error;
}

// Buttons
.elc-button {
  @include elc-button;
  [data-component] & {
    @include elc-button;
    @include elc-button-component-overrides;
  }
}
// Light Button
.elc-button.button--light {
  @include elc-button--light;
  [data-component] & {
    @include elc-button--light;
    @include elc-button-component-overrides;
  }
  &.elc-button--disabled,
  &:disabled {
    @include elc-button--light-disabled;
  }
}
// Dark Button
.elc-add-to-bag-button, // react component
.elc-button.button--dark {
  @include elc-button--dark;
  [data-component] & {
    @include elc-button--dark;
    @include elc-button-component-overrides;
  }
  &.elc-button--disabled,
  &:disabled {
    @include elc-button--dark-disabled;
  }
}
// Inverted Button
.elc-button.button--inverted {
  @include elc-button--inverted;
  [data-component] & {
    @include elc-button--inverted;
    @include elc-button-component-overrides;
  }
  &.elc-button--disabled,
  &:disabled {
    @include elc-button--inverted-disabled;
  }
}
// Custom Button - this loads inline styles so we just set basics here
.elc-button.button--custom {
  @include elc-button;
  color: $cr19-text;
  &:hover,
  &:active,
  &:visited {
    color: $cr19-text;
  }
}

// Text links
.text-link {
  @include text-link;
}

// Link style 1 - Dark with underline
.text-link--style-1,
a.text-link--style-1 {
  @include text-link--style-1;
  &.button {
    @include linkStyleForButton;
  }
}
// Link style 2 - Dark, underline on hover
.text-link--style-2,
a.text-link--style-2 {
  @include text-link--style-2;
  &.button {
    @include linkStyleForButton;
  }
}
// Link style 2 bold - Dark, underline on hover
.text-link--style-2-bold,
a.text-link--style-2-bold {
  @include text-link--style-2-bold;
  &.button {
    @include linkStyleForButton;
  }
}
// Link style 3 - Grey, underline on hover
.text-link--style-3,
a.text-link--style-3 {
  @include text-link--style-3;
  &.button {
    @include linkStyleForButton;
  }
}
// Link style 4 - Grey with underline
.text-link--style-4,
a.text-link--style-4 {
  @include text-link--style-4;
  &.button {
    @include linkStyleForButton;
  }
}
// Link - Style 5 - White with underline (for use on dark background)
.text-link--style-5,
a.text-link--style-5 {
  @include text-link--style-5;
  &.button {
    @include linkStyleForButton;
  }
}
// Link - Style 6 - White, underline on hover (for use on dark background)
.text-link--style-6,
a.text-link--style-6 {
  @include text-link--style-6;
  &.button {
    @include linkStyleForButton;
  }
}
// Link - Style 7 - Helvetica with underline
.text-link--style-7,
a.text-link--style-7 {
  @include text-link--style-7;
  &.button {
    @include linkStyleForButton;
  }
}
// Link - Style 8 - Helvetica with underline (for use on dark background)
.text-link--style-8,
a.text-link--style-8 {
  @include text-link--style-8;
  &.button {
    @include linkStyleForButton;
  }
}

// Dropdown - default
.dropdown-opt,
select.dropdown-opt {
  @include dropdown-opt;
}

// END 2019 Redesign helper classes

button.button-as-link {
  background: none;
  border: none;
  padding: 0;
  text-align: unset;
  letter-spacing: inherit;
  &:hover,
  &:active {
    background: none;
    border: none;
    padding: 0;
    text-align: unset;
    letter-spacing: inherit;
  }
}
