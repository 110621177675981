// build each media query for js ingestion
@each $mq in $mq-sync {
  @media screen and (min-width: nth($mq, 2)) {
    head {
      font-family: '#{nth($mq, 1)} #{nth($mq, 2)}';
    }
    body:after {
      content: '#{nth($mq, 1)} - min-width: #{nth($mq, 2)}';
      display: none;
    }
  }
}

// special styles to share media query info to JS
head {
  // set clear on head to show Unison is set up correctly
  clear: both;
  // store hash of all breakpoints
  title {
    font-family: '#{$mq-sync}';
  }
}

.pc-hidden {
  display: block;
  @media #{$medium-up} {
    display: none;
  }
}

.mobile-hidden {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
}

.svg-icon {
  @include svg-icon;
}

.pie {
  @include pie;
}

///
/// Text Alignment
///
.align-#{$ldirection} {
  text-align: $ldirection;
}

.align-center {
  text-align: center;
}

.align-#{$rdirection} {
  text-align: $rdirection;
}

// 2019 Redesign
// Mantle helpers
.mantle-media-asset {
  width: 100%;
  height: 100%;
  img,
  picture,
  video {
    max-width: 100%;
    width: 100%;
    // enabling height attr required css rule to be set to auto to maintain the natual aspect ratio of the asset
    &[height] {
      height: auto;
    }
  }
}

// 2019 Redesign
// Text Alignment
.text-align--#{$ldirection} {
  text-align: $ldirection;
}

.text-align--center {
  text-align: center;
}
.text-align--#{$rdirection} {
  text-align: $rdirection;
}

// 2019 Redesign
// New layout related helper classes
// The following are selectable choices in new content blocks
$cr19-percentages: 0 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100 120 140 160 180 200
  768 1024 1280 1366 1440;
$cr19-percentages-unit: unquote('%');
$pixel-unit: unquote('px');

@each $percentage in $cr19-percentages {
  // Maximum widths - percentages
  .max-width-#{$percentage} {
    max-width: #{$percentage}$cr19-percentages-unit;
  }
  .max-width-#{$percentage}px {
    max-width: #{$percentage}$pixel-unit;
  }
  // Margins - percentages
  .margin-#{$rdirection}-#{$percentage} {
    margin-#{$rdirection}: #{$percentage}$cr19-percentages-unit;
  }
  .margin-#{$ldirection}-#{$percentage} {
    margin-#{$ldirection}: #{$percentage}$cr19-percentages-unit;
  }
  .margin-top-#{$percentage} {
    margin-top: #{$percentage}$cr19-percentages-unit;
  }
  .margin-bottom-#{$percentage} {
    margin-bottom: #{$percentage}$cr19-percentages-unit;
  }
  .padding-#{$percentage} {
    padding: #{$percentage}$pixel-unit;
  }
  // PC only version
  .margin-top-#{$percentage}--pc {
    @media #{$cr19-large-up} {
      margin-top: #{$percentage}$cr19-percentages-unit;
    }
  }
  .margin-bottom-#{$percentage}--pc {
    @media #{$cr19-large-up} {
      margin-bottom: #{$percentage}$cr19-percentages-unit;
    }
  }
}

// 2019 Redesign
// Container max widths
// The following are selectable choices in new content blocks

.container-max-width {
  &-full {
    @include container-max-width-full;
  }
  &-x-large {
    @include container-max-width-x-large;
  }
  &-large {
    @include container-max-width-large;
  }
  &-medium {
    @include container-max-width-medium;
  }
  &-small {
    @include container-max-width-small;
  }
  &-x-small {
    @include container-max-width-x-small;
  }
}

// Container max widths padding rules
.container-max-width--padded {
  @include container-padding-rules;
}

// 2019 Redesign
// Formatter and block outside vertical formatting plus border
// The following are selectable choices in new content blocks
// 60 / 96 - with pad and border - 24 / 20 pad
// 40 / 48 - with pad and border - 24 / 20 pad
// Default
.container-vertical-default {
  margin: 0 auto;
}

.container-vertical-top-margin {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  margin-top: 40px;
  @media #{$cr19-large-up} {
    margin-top: 96px;
  }
  &--xsmall {
    margin-top: 20px;
    @media #{$cr19-large-up} {
      margin-top: 24px;
    }
  }
  &--small {
    margin-top: 40px;
    @media #{$cr19-large-up} {
      margin-top: 48px;
    }
  }
  &--large {
    margin-top: 60px;
    @media #{$cr19-large-up} {
      margin-top: 96px;
    }
  }
  &--has-border {
    border-top: $cr19-border-light solid 1px;
    padding-top: 20px;
    @media #{$cr19-large-up} {
      padding-top: 24px;
    }
  }
}

.container-vertical-bottom-margin {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  margin-bottom: 40px;
  @media #{$cr19-large-up} {
    margin-bottom: 96px;
  }
  &--xsmall {
    margin-bottom: 20px;
    @media #{$cr19-large-up} {
      margin-bottom: 24px;
    }
  }
  &--small {
    margin-bottom: 40px;
    @media #{$cr19-large-up} {
      margin-bottom: 48px;
    }
  }
  &--large {
    margin-bottom: 60px;
    @media #{$cr19-large-up} {
      margin-bottom: 96px;
    }
  }
  &--has-border {
    border-bottom: $cr19-border-light solid 1px;
    padding-bottom: 20px;
    @media #{$cr19-large-up} {
      padding-bottom: 24px;
    }
  }
}
// Border only with small pad
.container-vertical-top-border {
  border-top: $cr19-border-light solid 1px;
  padding-top: 20px;
  @media #{$cr19-large-up} {
    padding-top: 24px;
  }
}

.container-vertical-bottom-border {
  border-bottom: $cr19-border-light solid 1px;
  padding-bottom: 20px;
  @media #{$cr19-large-up} {
    padding-bottom: 24px;
  }
}

// Flex alignment helper classes
.horizontal-align-#{$ldirection},
.horizontal-align-default,
.horizontal-align-start,
.align-items-start {
  align-items: flex-start;
}

.horizontal-align-center,
.align-items-center {
  align-items: center;
}
.horizontal-align-#{$rdirection},
.horizontal-align-end,
.align-items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center,
.justify-default {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
// END 2019 Redesign layout related helper classes

// slick modifier to make vertical scrolling work on mobile devices
.slick {
  &-slider {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
}

.clickable {
  cursor: pointer;
}
