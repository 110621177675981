$guided-pick-container_width: 1040px;
$guided-pick-item-bg: #f9f9f9;
$guided-small-down: 767px;
$guided-small-min: 420px;
$guided-small-up: $guided-small-down + 1;
$guided-prod-desc: #333;

.guided_pick {
  @include swap_direction(margin, 36px auto);
  width: 100%;
  max-width: $guided-pick-container_width;
  .guided_pick--guided-pick-products {
    clear: #{$rdirection};
  }
  @include breakpoint($large-min) {
    @include swap_direction(padding, 0 20px);
  }
  @include swap_direction(padding, 0);
  .guided__loading--status {
    @include swap_direction(margin, 17px 0);
    @include swap_direction(padding, 7px 50px);
    display: none;
    float: #{$rdirection};
    img {
      width: 25px;
    }
    &.active {
      display: block;
    }
    @include breakpoint($guided-small-down) {
      @include swap_direction(padding, 14px 0);
      width: 100%;
      text-align: center;
    }
  }
  .pick-row {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    @include breakpoint($guided-small-down) {
      .product-tout {
        &:first-child {
          margin-#{$rdirection}: 1%;
        }
        &:last-child {
          margin-#{$ldirection}: 1%;
        }
      }
    }
  }
  .button-container-top {
    display: block;
    @include breakpoint($guided-small-down) {
      display: none;
    }
  }
  .guided_pick--offer-apply-button {
    @include swap_direction(margin, 20px 0);
    @include swap_direction(padding, 8px 27px);
    @include breakpoint($guided-small-down) {
      @include swap_direction(padding, 13px);
      @include swap_direction(margin, 0);
      width: 100%;
      text-align: center;
    }
    float: #{$rdirection};
    letter-spacing: 1px;
    font-size: 14px;
    cursor: pointer;
    width: auto;
    text-transform: uppercase;
    font-family: $nitti-bold-font-family;
    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;
    &.apply-offer-to-cart {
      background: $color-black;
      color: $color-white;
      cursor: pointer;
    }
    &.disabled {
      color: $color-black;
      font-weight: bold;
    }
  }
  img.guided_pick--offer-image {
    width: 100%;
    height: auto;
  }
  .product-tout {
    @include swap_direction(margin, 0 0 65px 0);
    color: $color-black;
    float: #{$ldirection};
    width: 32.66%;
    @include breakpoint($guided-small-down) {
      @include swap_direction(margin, 0 0 45px 0);
      width: 49%;
    }
    font-size: 20px;
    text-align: #{$ldirection};
    margin-#{$rdirection}: 1%;
    &:nth-of-type(3n + 3) {
      clear: #{$rdirection};
      margin-#{$rdirection}: 0;
      @include breakpoint($guided-small-down) {
        clear: none;
        margin-#{$rdirection}: 1%;
      }
    }
    &.product-hide {
      opacity: 0.2;
      cursor: default;
      a {
        cursor: default;
      }
    }
    .product {
      font-size: 20px;
      min-height: auto;
      .product-image {
        @include swap_direction(margin, 0 auto);
        background: $guided-pick-item-bg;
        margin-bottom: 20px;
        max-height: 349px;
      }
      .product-name {
        a {
          font-family: $helvetica-bold-font-family;
        }
        @include breakpoint($guided-small-down) {
          font-size: 14px;
        }
      }
      .product-info {
        width: auto;
        float: none;
      }
      .sizes {
        @include swap_direction(margin, 0);
        color: $guided-prod-desc;
        font-family: $helvetica-light-font-family;
        font-size: 20px;
        @include breakpoint($guided-small-down) {
          font-size: 14px;
          color: $color-black;
        }
        padding-#{$rdirection}: 20px;
      }
      .button {
        @include swap_direction(padding, 8px 27px);
        @include breakpoint($guided-small-down) {
          @include swap_direction(padding, 8px 16px);
          width: auto;
          border-width: 1px;
          font-size: 11px;
          line-height: 11px;
        }
        font-size: 14px;
        text-transform: uppercase;
        border: solid 1px $color-black;
        background: $color-white;
        color: $color-black;
        font-family: $nitti-bold-font-family;
        letter-spacing: 1px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        border-width: 2px;
        height: auto;
        &:hover {
          color: $color-black;
        }
        &.selected {
          background: $color-black;
          color: $color-white;
        }
      }
    }
  }
}

.guided_popup_overlay {
  @include swap_direction(padding, 50px);
  font-size: 18px;
  &.dynamic-pick-overlay {
    padding: 20px 40px;
  }
}

.sticky__guided--container {
  @include swap_direction(padding, 5px);
  .sticky__guided--loading {
    @include swap_direction(padding, 7px 50px);
    display: none;
    float: #{$rdirection};
    @include breakpoint($guided-small-down) {
      float: none;
      text-align: center;
    }
    img {
      width: 25px;
    }
    &.active {
      display: block;
    }
  }
  @include breakpoint($guided-small-down) {
    @include swap_direction(padding, 10px 20px);
    display: inline-block;
    width: 100%;
    &.sample_static {
      position: fixed;
      bottom: 0;
      background: $color-white;
    }
  }
  .sticky__guided--remaining {
    width: 40%;
    float: #{$ldirection};
    font-size: 22px;
    font-weight: normal;
    color: $color-light-grey;
    margin-top: 4px;
    text-align: center;
    strong {
      color: $color-black;
    }
    @include breakpoint($large-min) {
      font-size: 18px;
      margin-top: 6px;
    }
    @include breakpoint($guided-small-down) {
      display: none;
    }
  }
  .sticky__guided--continue {
    @include swap_direction(margin, 0 20px 0 0);
    @include swap_direction(padding, 8px 27px);
    float: #{$rdirection};
    font-size: 14px;
    letter-spacing: 1px;
    border: 1px solid $color-black;
    text-transform: uppercase;
    font-family: $nitti-bold-font-family;
    width: auto;
    &.apply-offer-to-cart {
      cursor: pointer;
      background: $color-black;
      color: $color-white;
    }
    @include breakpoint($guided-small-down) {
      @include swap_direction(margin, 0);
      width: 100%;
      text-align: center;
    }
  }
}
