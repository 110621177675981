// mobile-only styles

html,
body {
  height: auto;
  min-height: 415px;
  // margin: 0 auto;
}

html {
  &.elc-sticky-container--active {
    height: 100%;
    body {
      height: 100%;
    }
    .main_container {
      overflow: auto;
      overflow-x: clip;
    }
  }
}

body {
  font-family: sans-serif;
  font-size: 14px;
  width: 100%;
  background: #ebebeb;
}

.body_container {
  width: 100%;
  min-width: 320px;
  height: 100%;
  min-height: 415px;
  margin: 0 auto;
}

.not_mobile {
  display: none;
}

// @media only screen and (min-width: 1224px) {
//  html,
//  body,
//  .body_container {
//    position: relative;
//    width: 320px;
//            margin: 0 auto;
//  }
// }

// @media only screen and (min-width: 1224px) {
//  body,
//  .body_container {
//    width: 320px;
//  }
// }

.main_container {
  width: 100%;
  height: 100%;
  min-height: 415px;
  background: white;
  overflow: hidden;
}

#main {
  padding: 0;
}

.main {
  width: 100%;
  min-width: 320px;
  background: white;
}

.content_container {
  clear: both;
}

#top {
  height: 68px;
  background: whitesmoke;
  position: static;
  @if $cr19 == true {
    background: $color-white;
    height: auto;
  }
}
/* Adjust top for CL CA */

.domain-ca #top {
  height: 108px;
  @if $cr19 == true {
    height: auto;
  }
}

#top,
#header {
  width: 100%;
  min-width: 320px;
}

#header {
  height: 68px;
  overflow: hidden;
  padding-top: 5px;
  .btn_navigation {
    height: 56px;
    background: image-url('mobile/btn_navigation.png') no-repeat 0 0;
    border-#{$rdirection}: 1px solid $color-grey;
  }
}

.main_content {
  background: white;
  padding: 10px;
}

.navigation_container {
  position: relative;
  float: #{$ldirection};
  width: 0;
  overflow-x: hidden;
  height: 550px;
  min-height: 450px;
  background: #ebebeb;
  z-index: 9;
  .navigation {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .field-mobile-menu {
    > .menu {
      #{$ldirection}: 0;
    }
  }
  ul {
    &.menu {
      position: absolute;
      padding: 0;
      margin: 0;
      width: 100%;
      top: 0;
      #{$ldirection}: 100%;
    }
    &.nav_lvl2 {
      li {
        &:first-child a {
          background: #d9d9d9;
        }
      }
    }
    li {
      a {
        display: block;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
        text-decoration: none;
        color: #121212;
        text-indent: 18px;
        white-space: nowrap;
        border-bottom: 1px solid white;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      a:active {
        background: #d9d9d9;
      }
    }
  }
}

#navigation_control {
  display: none;
  position: absolute;
  top: 0;
  #{$rdirection}: 0;
  width: 35%;
  height: 100%;
  background: transparent;
  opacity: 0.3;
  z-index: 3;
}
/* Navigation */

.device-mobile .invisible {
  display: none;
}

.navigation_container .field-mobile-menu ul.menu li {
  list-style-type: none;
}

.navigation_container ul li {
  a {
    height: auto;
    line-height: 38px;
    @include swap_direction(padding, 4px 30px 4px 18px);
    text-indent: 0;
    white-space: normal;
  }
  .welcome-user {
    background: none repeat scroll 0 0 #ebebeb;
    border-bottom: 1px solid white;
    color: #121212;
    display: block;
    font-size: 22px;
    height: auto;
    line-height: 38px;
    @include swap_direction(padding, 4px 30px 4px 18px);
    text-decoration: none;
    text-indent: 0;
    white-space: pre-wrap;
  }
}

.field-mobile-menu li.parent a span {
  vertical-align: top;
}

.parent .icon {
  background: url('/media/export/cms/mobile/prev-next-arrows.png') no-repeat scroll 0 -34px rgba(0, 0, 0, 0);
  display: inline-block;
  height: 30px;
  @include swap_direction(margin, 0 0 0 80px);
  position: absolute;
  #{$rdirection}: 0;
  width: 27px;
  img {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    width: 30px;
  }
}

.parent-back a,
.depth-1 .first a,
.depth-2 .first a {
  padding-#{$ldirection}: 20px;
  padding-#{$rdirection}: 0;
  text-indent: 18px;
}

.first.parent-back .icon,
.field-mobile-menu
  ul
  li.first.parent-my-account
  .menu-container.depth-1.my-account
  li.first.parent-back
  a
  span.icon,
.depth-1 .first .icon,
.depth-2 .first .icon {
  background: transparent url('/media/export/cms/mobile/prev-next-arrows.png') no-repeat scroll 0
    5px;
  #{$ldirection}: 0;
  margin-#{$ldirection}: 0;
}

.navigation_container ul li {
  &.parent-my-account a span.my-account {
    padding-#{$ldirection}: 0; /* padding-#{$ldirection}: 15px; */
  }
  &.parent-checkout a span.checkout {
    padding-#{$ldirection}: 15px;
  }
}

.menu-container.depth-1.checkout .checkout-icon {
  display: none;
}

.field-mobile-menu ul li {
  /* A hack to get rid of the duplicated icon */
  &.parent-checkout .checkout-icon:first-child {
    background: url('/media/export/cms/icon-bag.png') no-repeat scroll 0 0 transparent;
    display: inline-block;
    height: 28px;
    margin-#{$ldirection}: 3px;
    margin-top: 3px;
    width: 22px;
  }
  &.parent-checkout .checkout-icon:last-child {
    display: none;
  }
  &.parent-my-account .user-pic {
    background: url('/media/export/cms/avatar.png') no-repeat scroll 0 0 transparent;
    display: none; /* display: inline-block; */
    height: 23px;
    margin-#{$ldirection}: 3px;
    margin-top: 8px;
    width: 23px;
  }
}

.checkout .parent-back .checkout-icon img {
  display: none;
}

.my-account .welcome {
  margin-#{$ldirection}: 45px;
  &:after {
    content: 'Welcome!';
  }
}
/* Logged in menu items. Hidden for Drupal/PG anonymous users */

.not-logged-in {
  .parent-logout {
  }
}

.logged-in .parent-login,
.elc-user-state-logged-in .parent-login {
  display: none;
}

.elc-user-state-anonymous {
  .parent-logout,
  .parent-my-purchases,
  .parent-reorder-from-past-purchases {
    display: none;
  }
  .parent-login {
    display: inline;
  }
}

.elc-user-state-logged-in .parent-logout {
  display: inline;
}
/* Mobile header adjustments */

@media screen and (max-width: 767px) {
  #top #header {
    padding-top: 8px;
    text-align: center;
    position: relative;
    .btn_navigation {
      height: 52px;
      background-position: 0 -9px;
      @media only screen and (max-width: 320px) {
        background-position: -6px -9px;
      }
    }
    .block-template-search-mobile-v1 {
      .clearfix:first-child {
        overflow: inherit;
      }
    }
    .mobile_checkout_backbutton {
      display: none;
    }
    .btn_navigation,
    .block-template-search-mobile-v1,
    .block-template-gnav-utility-button-mobile-v1,
    .block-template-gnav-cart-v1 {
      float: #{$ldirection};
      width: 13%;
      @media screen and (max-width: 767px) and (orientation: landscape) {
        width: 7%;
      }
      @media only screen and (max-width: 568px) and (orientation: landscape) {
        width: 10%;
      }
    }
    .block-template-gnav-cart-v1 {
      width: 14%;
    }
    .block-template-site-logo-responsive-v1 {
      float: #{$ldirection};
      width: 47%;
      @media screen and (max-width: 767px) and (orientation: landscape) {
        width: 70%;
      }
      @media only screen and (max-width: 568px) and (orientation: landscape) {
        width: 60%;
      }
    }
  }
}
/* Footer, bottom, menu, copyright */

.device-mobile #footer .footer-bottom {
  text-align: #{$ldirection};
  .field-mobile-menu ul.menu {
    font-size: 11px;
    padding-#{$ldirection}: 0;
    > li {
      &.first,
      &.last {
        border: none;
        @include swap_direction(padding, 0 10px 0 0);
      }
      &.last {
        clear: both;
      }
      a {
        color: $color-text-black;
        font-size: 12px;
        height: 5px;
        margin: 0;
        padding: 0;
      }
      &.last a {
        color: $color-cl-green-2;
        display: block;
      }
      border-#{$ldirection}: 1px solid $color-text-black;
      color: $color-text-black;
      float: #{$ldirection};
      height: 13px;
      line-height: 13px;
      @include swap_direction(margin, 0 0 5px);
      @include swap_direction(padding, 0 10px 0px);
    }
  }
  .block-template-basic-responsive-v1 p {
    clear: both;
    display: block;
    font-size: 11px;
    margin-#{$ldirection}: 0;
    @include swap_direction(padding, 10px 0 0);
  }
  .inner {
    padding: 0 10px;
  }
}
/* iPhone 3, 4 and 5 portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li {
    margin: 0 0 5px;
  }
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-3,
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-5 {
    border-#{$ldirection}: none;
    padding-#{$ldirection}: 0;
  }
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-4 {
    padding-#{$rdirection}: 30px;
  }
}
/* iPhone 3, 4 and 5 landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li {
    margin: 0;
  }
  .device-mobile #footer .footer-bottom .field-mobile-menu ul.menu > li.item-4 {
    border-#{$ldirection}: none;
    padding-#{$ldirection}: 0;
  }
}
/* Flexslider mobile */
/* Show nav arrows on mobile */

.device-mobile {
  .flex-direction-nav {
    display: block;
  }
  .flex-control-nav {
    bottom: 5.35%;
  }
  .flex-control-paging li a {
    height: 13px;
    width: 13px;
  }
}

.sbHolder {
  width: 100%;
}
/* Account: Past Purchases */
.account-past-purchases .past-purchases {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 0;
  padding-top: 10px;
  .best-sellers .products {
    display: flex;
    gap: 0.4rem;
  }
}

.account-past-purchases .past-purchases .best-sellers .product {
  flex: 1 1 0;
  padding: 0;
  width: unset;
  .my_account_product {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .product-image {
    width: 100%;
    min-height: 87px;
  }
  .product-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 0;
    .price {
      order: 2;
    }
    .product-name {
      flex-grow: 1;
      order: 0;
    }
    .reviews {
      order: 1;
    }
  }
}

.account-past-purchases header h1 {
  font-size: 35px;
  padding-#{$ldirection}: 10px;
}

.account-past-purchases .past-purchases .order-header,
.account-past-purchases .past-purchases .order.accordion-header {
  border-bottom: 1px solid #d3d3d3;
  overflow: hidden;
  padding: 0;
}

.account-past-purchases .past-purchases .order-header .created-date,
.account-past-purchases .past-purchases .order.accordion-header .created-date {
  color: #121212;
  display: inline;
  float: #{$ldirection};
  font-size: 22px;
  line-height: 45px;
  margin-bottom: 0;
  margin-#{$rdirection}: 0;
  margin-top: 0;
  @include swap_direction(padding, 0 0 0 10px);
  width: 45%;
}

.account-past-purchases .past-purchases .order-header .view-details,
.account-past-purchases .past-purchases .order.accordion-header .view-details {
  float: #{$rdirection};
  padding-#{$rdirection}: 0;
  width: 45%;
}

.account-past-purchases .past-purchases .order-header .view-details a.view-details-button,
.account-past-purchases
  .past-purchases
  .order.accordion-header
  .view-details
  a.view-details-button {
  background: none repeat scroll 0 0 #ffffff;
  color: $color-cl-green-2;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  @include swap_direction(padding, 0 15px 0 0);
  text-align: #{$rdirection};
  text-transform: capitalize;
}

.account-past-purchases ul.order-details {
  padding: 10px;
  li.item {
    color: #231f20;
    span.label {
      color: #808080;
    }
  }
}
/* Account: Past Purchases: View order details */
.account-past-purchases .past-purchases .order-info.accordion-content {
  margin-top: 0;
}

.account-past-purchases .past-purchases .order-info.accordion-content h2 {
  color: #121212;
  font-size: 22px;
  margin: 15px 0 5px;
  text-transform: capitalize;
  padding: 0;
}

.account-past-purchases .past-purchases .order-info.accordion-content .order-details {
  margin: 0 4%;
  width: auto;
}

.account-past-purchases .past-purchases .order-info.accordion-content .address {
  padding: 0 15px 15px;
}

.account-past-purchases .past-purchases .order-info.accordion-content .address .customer-name {
  color: #121212;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products {
  border-top: 1px solid #d3d3d3;
  padding: 0 10px;
  width: 100%;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products .product {
  border-bottom: 1px solid #d3d3d3;
  border-top: none;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products .my-account-sku {
  margin: 0;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products ul {
}

.account-past-purchases .past-purchases .order-info.accordion-content .products ul li.product {
  overflow: hidden;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  ul
  li
  .my-account-sku {
}

a.button {
  border-radius: 0;
}

.account-past-purchases .past-purchases a.button,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  ul
  li
  a.button.add-to-bag {
  border-radius: 0;
  margin: 20px auto 0;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products .samples {
  border-top: none;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .samples
  .my_account_sample {
  float: none;
  width: 100%;
}

.device-mobile
  .account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .samples
  .my_account_sample
  .product-image {
  display: block;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .samples
  .my_account_sample
  .product-image
  img {
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .samples
  .my_account_sample
  .product-info {
  float: none;
  width: 100%;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .samples
  .my_account_sample
  .product-info
  .product-name {
  color: #121212;
}

.account-past-purchases .past-purchases .order-info.accordion-content .products .product-footer {
  color: #121212;
  padding: 0;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .sales_tax,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftcard_redemption,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .subtotal,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .shipping,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftwrap,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .discount,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .total {
  border-bottom: 1px solid #d3d3d3;
  font-size: inherit;
  margin: 0;
  padding: 5px 0;
  width: 100%;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .total {
  border-bottom: none;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .sales_tax
  span,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftcard_redemption
  span,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .subtotal
  span,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .shipping
  span,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftwrap
  span,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .total
  span {
  display: inline;
  float: #{$ldirection};
  margin: 0;
  text-transform: capitalize;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .sales_tax
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftcard_redemption
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .subtotal
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .shipping
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .giftwrap
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .discount
  span:last-child,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product-footer
  .total
  span:last-child {
  float: #{$rdirection};
}
/* Account: Past Purchases: View order details: Order */
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-image {
  overflow: hidden;
  width: 38%;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-image
  img {
  margin-#{$ldirection}: -20px;
  width: 158px;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info {
  width: 60%;
}
/* Account: Past Purchases: Product Info: Shades & Sizes */
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .shades,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .sizes {
  margin: 10px 0;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .shades
  .shade.active
  .search-swatch-container {
  display: inline;
  float: #{$ldirection};
  height: 15px;
  @include swap_direction(margin, 0 10px 0 0);
  width: 15px;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .shades
  .shade.active
  .search-swatch-container
  .single {
  height: 100%;
  width: 100%;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .shades
  .shade.active
  .shade-name,
.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .sizes
  .size.active {
  font-size: 12px;
}

.account-past-purchases
  .past-purchases
  .order-info.accordion-content
  .products
  .product
  .product-info
  .sizes
  .size.active {
  margin: 0;
}
/* Account: Past Purchases: No Orders */
.account-past-purchases .past-purchases .no-orders {
  margin: 0;
  overflow: hidden;
  padding: 0 20px 20px;
}

.account-past-purchases .past-purchases .no-orders h2 {
  color: #121212;
  font-size: 22px;
  line-height: 22px;
  margin: 0 0 20px;
  padding: 0;
}

.account-past-purchases .past-purchases .no-orders a.button {
  margin: 0;
  padding: 0;
}
/* Accordion */
.account-past-purchases .past-purchases .order-header-wrapper {
  position: relative;
}

.account-past-purchases .past-purchases .order-header-wrapper .order-details .item.first.caret {
  background: url('/media/export/images/qs-caret.png') no-repeat scroll 50% 0 rgba(0, 0, 0, 0);
  display: block;
  height: 12px;
  padding: 0;
  position: absolute;
  #{$rdirection}: 45px;
  top: 35px;
  width: 22px;
  z-index: 9000;
}

.account-past-purchases .past-purchases .order.accordion-header .view-details a span.close {
  display: none;
}

.account-past-purchases .past-purchases .order.accordion-header.active .view-details a span.close {
  background: url('/sites/clinique/themes/cl_base/img/icon-close.png') no-repeat scroll 0 0
    rgba(0, 0, 0, 0);
  bottom: 35px;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: block;
  height: 21px;
  position: absolute;
  #{$rdirection}: 15px;
  width: 20px;
}

.account-past-purchases {
  .shipment-products {
    .product-header {
      display: none;
    }
  }
}

.nav-promo {
  position: relative;
  height: 40px;
  float: #{$ldirection};
  width: 100%;
  clear: both;
  overflow: hidden;
  -webkit-box-shadow: -2px 8px 7px -5px #dedede;
  box-shadow: -2px 8px 7px -5px #dedede;
}

.offer-promo {
  float: #{$ldirection};
  width: 100%;
  clear: both;
  overflow: hidden;
  margin-top: 10px;
}

#confirm {
  .privacy_policy_container {
    clear: both;
    input {
      width: 13px;
    }
  }
}

.start-over-link,
.cancel-order,
.cards_note a,
.refer-friend__referals a,
.sms-signup-block__promotion-text a,
.loyalty__panel__receipt__clsr_msg a,
.email-signup-block__email-promotion-text a {
  @include text-link;
  @include text-link--style-1;
  line-height: 1.5;
}

.overlay-link,
.profile-info__item,
.signin__privacy-policy,
.signin__financial-incentive,
.sms-promo--terms {
  line-height: 1.6;
}
